<template>
  <b-card title="Detail Item">
    <b-row>
      <b-col cols="12">
        <b-form-group
            label="Item Model"
            description="Item Model"
          >
            <b-form-input
              v-model="item.itemModel"
              type="text"
              placeholder="Enter Item Model"
              required
              disabled
            ></b-form-input>
        </b-form-group>

        <b-form-group
            label="Customer PO"
            description="Customer PO"
          >
            <b-form-input
              v-model="item.customerPo"
              type="text"
              placeholder="Enter Customer PO"
              required
              disabled
            ></b-form-input>
        </b-form-group>

        <b-form-group
            label="Item Description"
            description="Item Description"
          >
            <b-form-input
              v-model="itemCatalogue.description"
              type="text"
              placeholder="Enter Item Description"
              required
              disabled
            ></b-form-input>
        </b-form-group>

        <b-form-group
            label="Status"
            description="status"
          >
            <b-form-input
              v-model="item.status"
              type="text"
              disabled
            ></b-form-input>
        </b-form-group>

        <b-form-group
            label="Item Barcode"
            description="Item Barcode"
          >
            <b-form-input
              v-model="item.barcode"
              type="text"
              placeholder="Enter Item Barcode"
              required
              disabled
            ></b-form-input>
        </b-form-group>

        <b-form-group
            label="Item Sap DO"
            description="Item Sap DO"
          >
            <b-form-input
              v-model="item.sapDo"
              type="text"
              placeholder="Enter Item sap DO"
              required
              disabled
            ></b-form-input>
        </b-form-group>

        <b-form-group
            label="Item Sap SO"
            description="Item Sap SO"
          >
            <b-form-input
              v-model="item.sapSo"
              type="text"
              placeholder="Enter Item Sap So"
              required
              disabled
            ></b-form-input>
        </b-form-group>

        

        <b-form-group
            label="Dealer Code"
            description="Dealer Code"
          >
            <b-form-input
              v-model="item.dealerCode"
              type="text"
              placeholder="Enter Dealer Code"
              required
              disabled
            ></b-form-input>
        </b-form-group>

        <b-form-group
            label="Installer Name"
            description="Installer Name"
          >
            <b-form-input
              v-model="installerName"
              type="text"
              placeholder="Enter Installer Name"
              required
              disabled
            ></b-form-input>
        </b-form-group>

        <b-form-group
            label="Installation Date"
            description="Installation Date"
          >
            <b-form-input
              v-model="item.installationDate"
              type="text"
              placeholder="Enter Installation Date"
              required
              disabled
            ></b-form-input>
        </b-form-group>

        <b-form-group
            label="Warranty Status"
            description="Warranty Status"
          >
            <b-form-input
              v-model="item.warrantyStatus"
              type="text"
              placeholder="Warranty Status"
              required
              disabled
            ></b-form-input>
        </b-form-group>

        <b-form-group
            label="PCode"
            description="PCode"
          >
            <b-form-input
              v-model="item.pcode"
              type="text"
              placeholder="PCode"
              required
              disabled
            ></b-form-input>
        </b-form-group>

        <b-form-group
            v-if="item.warrantyStatus=='Registered'"
            label="Certificate Number"
            description="Certificate Number"
          >
            <b-form-input
              v-model="item.certNo"
              type="text"
              placeholder="Certificate Number"
              required
              disabled
            ></b-form-input>
        </b-form-group>

        <b-form-group
            label="Register Date"
            description="Register Date"
          >
            <b-form-input
              v-model="item.registerDate"
              type="text"
              placeholder="Register Date"
              required
              disabled
            ></b-form-input>
        </b-form-group>

        <b-form-group
            label="General Warranty End Date"
            description="General Warranty End Date"
          >
            <b-form-input
              v-model="item.generalWarrantyEndDate"
              type="text"
              placeholder="General Warranty End Date"
              required
              disabled
            ></b-form-input>
        </b-form-group>

        <b-form-group
            label="Compressor Warranty End Date"
            description="Compressor Warranty End Date"
          >
            <b-form-input
              v-model="item.compressorWarrantyEndDate"
              type="text"
              placeholder="Compressor Warranty End Date"
              required
              disabled
            ></b-form-input>
        </b-form-group>

        <b-form-group
            label="Customer First Name"
            description="Customer Last Name"
          >
            <b-form-input
              v-model="customer.nameFirst"
              type="text"
              placeholder="Contact Number"
              required
              disabled
            ></b-form-input>
        </b-form-group>

        <b-form-group
            label="Customer Last Name"
            description="Contact Last Name"
          >
            <b-form-input
              v-model="customer.nameLast"
              type="text"
              placeholder="Customer Last Name"
              required
              disabled
            ></b-form-input>
        </b-form-group>

        <b-form-group
            label="Contact Number"
            description="Contact Number"
            v-if="customer.type === 'Company'"
          >
            <b-form-input
              v-model="customer.companyName"
              type="text"
              placeholder="Contact Number"
              required
              disabled
            ></b-form-input>
        </b-form-group>

        <b-form-group
            label="Contact Number"
            description="Contact Number"
          >
            <b-form-input
              v-model="customer.phone"
              type="text"
              placeholder="Contact Number"
              required
              disabled
            ></b-form-input>
        </b-form-group>

        <b-form-group
            label="Email"
            description="Email"
          >
            <b-form-input
              v-model="customer.email"
              type="text"
              placeholder="Email"
              required
              disabled
            ></b-form-input>
        </b-form-group>

        <b-form-group
            label="Address"
            description="Address"
          >
            <b-form-input
              v-model="customer.address"
              type="text"
              placeholder="Address"
              required
              disabled
            ></b-form-input>
        </b-form-group>

      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import axios from '@/axios';
import { dateFormat } from '@/utils/utils.js'

export default {
  data() {
    return {
      item: {},
      status:'',
      }
  },
  mounted() {
    this.loadItem();
  },
  methods: {
    dates(date){
      return dateFormat(date)
    },
    loadItem() {
      let idItem = this.$route.params.id;

      axios.get(`sales-and-purchase/inventory/detail/${idItem}`).then((response) => {
        this.item = response.data.data._doc;
        /** Barcode front end rule. If sapDO is empty and scanStatus is Not Scan, barcode and serial is equal to Not Set Up
          
          if(row.sapDo===''&&row.scanStatus==="Not Scan"){
            barcode = 'Not Set Up';
            serial = 'Not Set Up'
          }
        */
        var installationDate= this.item.installationDate;
        var registerDate= this.item.registerDate;
        var generalWarrantyEndDate= this.item.generalWarrantyEndDate
        var compressorEnd=this.item.compressorWarrantyEndDate;
        console.log('compressor end ',this.item)
        
        this.item.installationDate=(installationDate)?this.dates(new Date(installationDate)):"-";
        this.item.registerDate= (registerDate)?this.dates(new Date(registerDate)):"-";
        this.item.generalWarrantyEndDate=(generalWarrantyEndDate)? this.dates(new Date(generalWarrantyEndDate)):"No General Warranty End Date"
        this.item.compressorWarrantyEndDate= (compressorEnd)?this.dates(new Date(compressorEnd)):"No Compressor"
        this.item.barcode = (this.item.scanStatus==="Not Scan")?'-': this.item.barcode;

        if(this.item.scanStatus==="Not Scan" && this.item.unscanUser === undefined){
          this.item.status = 'Not Scan'
        }
        if(this.item.scanStatus==="Not Scan" && this.item.unscanUser !== undefined){
          this.item.status = 'Unscanned'
        }
        if(this.item.scanStatus==="Scan"){
          this.item.status = 'Scan'
        }
      }).catch((e) => {
        this.$router.go(-1);
      })
    }
  },
  computed: {
    dealer() {
      if (this.item.dealer !== null && this.item.dealer !== undefined) {
        return this.item.dealer;
      }

      return {};
    },

    customer() {
      if (this.item.customer !== null && this.item.customer !== undefined) {
        return this.item.customer;
      }

      return {};
    },

    installer() {
      if (this.item.installer !== null && this.item.installer !== undefined) {
        return this.item.installer;
      }

      return {};
    },

    itemCatalogue() {
      if (this.item.itemCatalogue !== null && this.item.itemCatalogue !== undefined) {
        return this.item.itemCatalogue;
      }

      return {};
    },

    installerName() {
      if (this.installer.name === undefined) {
        return this.item.installerName
      }

      return this.installer.name;
    }
  }
}
</script>
